<template>
  <!-- Active Users Card -->
  <a-card :bordered="false" class="dashboard-bar-chart">
    <template #title>
      <h6>{{type == 'msg' ? '7天群活跃 TOP10' : '群成员数 TOP10'}}</h6>
      <!-- <p>than last year <span class="text-success">+20%</span></p> -->
    </template>
    <chart-bar :height="310" :data="data"></chart-bar>
  </a-card>
  <!-- Active Users Card -->
</template>

<script>

// Bar chart for "Active Users" card.
import ChartBar from '../Charts/ChartBar';

export default ({
  components: {
    ChartBar,
  },
  props: [
    'data',
    'type'
  ],
  watch: {
    data: {
      handler (val, oldVal) {
        console.log(val)
      },
      immediate: true,
    },
  },
  data() {
    return {
      // Data for bar chart.
    }
  },
})

</script>