<template>
  <div>
    <a-row :gutter="24">
      <a-col :span="24" :lg="12" :xl="8" class="mb-24" v-for="(stat, index) in stats" :key="index">
        <WidgetCounter :title="stat.title" :value="stat.value" :prefix="stat.prefix" :suffix="stat.suffix"
          :icon="stat.icon" :status="stat.status"></WidgetCounter>
      </a-col>
    </a-row>
    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="12" class="mb-24">
        <CardBarChart v-if="barChartData.labels.length" :data="barChartData" type="msg"></CardBarChart>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-24">
        <CardBarChart v-if="barChartData1.labels.length" :data="barChartData1" type="user"></CardBarChart>
      </a-col>
    </a-row>
    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="24" class="mb-24">
        <a-input-search
          v-model:value="keyword"
          placeholder="输入群名关键词"
          style="width: 300px"
        />
      </a-col>
    </a-row>
    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="24" class="mb-24">
        <CardAuthorTable :data="getGroups()" :columns="tableColumns" :loading="loading" @refresh="getWxworkGroupNames"></CardAuthorTable>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getWxworkGroupNames } from '../http/api';
import CardBarChart from '../components/Cards/CardBarChart';
import CardLineChart from '../components/Cards/CardLineChart';
import WidgetCounter from '../components/Widgets/WidgetCounter';
import CardAuthorTable from '../components/Cards/CardAuthorTable';

const tableColumns = [{
  title: '群名',
  dataIndex: 'group_name',
}, {
  title: '群负责人',
  dataIndex: 'leader',
  scopedSlots: { customRender: 'leader' },
}, {
  title: '群成员数',
  dataIndex: 'total_users',
}, {
  title: '近七天消息数',
  dataIndex: 'last_seven_msg_count',
}, {
  title: '操作',
  scopedSlots: { customRender: 'editBtn' },
  width: 50,
}]

export default ({
  components: {
    CardBarChart,
    CardLineChart,
    WidgetCounter,
    CardAuthorTable,
  },
  data() {
    return {
      keyword: '',
      loading: false,
      tableColumns,
      stats: [],
      groups: [],
      barChartData: {
        labels: [],
        datasets: [{
          label: "近七天消息数",
          backgroundColor: '#fff',
          borderWidth: 0,
          borderSkipped: false,
          borderRadius: 6,
          data: [],
          maxBarThickness: 30,
        }]
      },
      barChartData1: {
        labels: [],
        datasets: [{
          label: "群成员数",
          backgroundColor: '#fff',
          borderWidth: 0,
          borderSkipped: false,
          borderRadius: 6,
          data: [],
          maxBarThickness: 30,
        }]
      }
    }
  },
  mounted() {
    this.loading = true
    this.getWxworkGroupNames()

  },
  methods: {
    getGroups() {
      let groups = this.groups || []
      if (this.keyword) {
        groups = groups.filter(item => item.group_name.toLowerCase().includes(this.keyword.toLowerCase()))
      }
      return groups
    },
    getWxworkGroupNames() {
      getWxworkGroupNames().then(res => {
        console.log(res)
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let groupsStat = res.data.wxworkChat.groupsStat
        let groupsCount = groupsStat.length
        let totalUsers = groupsStat.reduce((total, item) => {
          return total + item.total_users
        }, 0)
        let totalMsg = groupsStat.reduce((total, item) => {
          return total + item.last_seven_msg_count
        }, 0)
        let oldGroupsCount = localStorage.getItem('groupsCount') || 0
        let oldTotalUsers = localStorage.getItem('totalUsers') || 0
        let oldTotalMsg = localStorage.getItem('totalMsg') || 0
        let groupsCountSuffix = groupsCount > oldGroupsCount ? '+' + ((groupsCount - oldGroupsCount) / oldGroupsCount * 100).toFixed(2) + '%' : '-' + ((oldGroupsCount - groupsCount) / oldGroupsCount * 100).toFixed(2) + '%'
        let totalUsersSuffix = totalUsers > oldTotalUsers ? '+' + ((totalUsers - oldTotalUsers) / oldTotalUsers * 100).toFixed(2) + '%' : '-' + ((oldTotalUsers - totalUsers) / oldTotalUsers * 100).toFixed(2) + '%'
        let totalMsgSuffix = totalMsg > oldTotalMsg ? '+' + ((totalMsg - oldTotalMsg) / oldTotalMsg * 100).toFixed(2) + '%' : '-' + ((oldTotalMsg - totalMsg) / oldTotalMsg * 100).toFixed(2) + '%'
        localStorage.setItem('groupsCount', groupsCount)
        localStorage.setItem('totalUsers', totalUsers)
        localStorage.setItem('totalMsg', totalMsg)
        // 取last_seven_msg_count最高的10个
        this.groups = JSON.parse(JSON.stringify(groupsStat))
        this.groups = this.groups.sort((a, b) => b.last_seven_msg_count - a.last_seven_msg_count)
        let activeGroups = groupsStat.sort((a, b) => b.last_seven_msg_count - a.last_seven_msg_count).slice(0, 10)
        this.barChartData.labels = activeGroups.map(item => item.group_name)
        this.barChartData.datasets[0].data = activeGroups.map(item => item.last_seven_msg_count)
        let usersGroups = groupsStat.sort((a, b) => b.total_users - a.total_users).slice(0, 10)
        this.barChartData1.labels = usersGroups.map(item => item.group_name)
        this.barChartData1.datasets[0].data = usersGroups.map(item => item.total_users)
        // this.groupsStat = groupsStat
        this.stats = [{
          title: "群总数",
          value: +groupsCount,
          prefix: "",
          suffix: groupsCountSuffix,
          icon: `
            <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
              <path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
            </svg>`,
        }, {
          title: "群总人数",
          value: +oldTotalUsers,
          prefix: "",
          suffix: totalUsersSuffix,
          icon: `
            <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
              <path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
              <path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
              <path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
            </svg>`,
        }, {
          title: "7天消息总数",
          value: +totalMsg,
          prefix: "",
          suffix: totalMsgSuffix,
          icon: `
            <svg t="1683783201037" class="" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5477" width="22" height="22"><path d="M386.16744 386.146667A297.946667 297.946667 0 0 1 597.354107 298.666667h70.76q-2.546667-7.22-5.553334-14.32A255.806667 255.806667 0 0 0 426.68744 128H256.020773a256 256 0 0 0-170.666666 446.82V704a21.333333 21.333333 0 0 0 36.42 15.086667l84-84A257.893333 257.893333 0 0 0 256.020773 640h45.686667a301.373333 301.373333 0 0 1-3.02-42.666667 297.946667 297.946667 0 0 1 87.48-211.186666zM213.354107 426.666667a42.666667 42.666667 0 1 1 42.666666-42.666667 42.666667 42.666667 0 0 1-42.666666 42.666667z m790.54 71.013333A255.806667 255.806667 0 0 0 768.020773 341.333333H597.354107a256 256 0 1 0 0 512h170.666666a257.893333 257.893333 0 0 0 50.226667-4.933333l84 84A21.333333 21.333333 0 0 0 938.68744 917.333333v-129.18a256.253333 256.253333 0 0 0 65.206667-290.473333zM512.020773 640a42.666667 42.666667 0 1 1 42.666667-42.666667 42.666667 42.666667 0 0 1-42.666667 42.666667z m170.666667 0a42.666667 42.666667 0 1 1 42.666667-42.666667 42.666667 42.666667 0 0 1-42.666667 42.666667z m170.666667 0a42.666667 42.666667 0 1 1 42.666666-42.666667 42.666667 42.666667 0 0 1-42.666666 42.666667z" fill="#ffffff" p-id="5478"></path></svg>`,
        }]
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
  },
})

</script>

<style lang="scss">
.mb-24 {
  margin-bottom: 24px;
}
</style>