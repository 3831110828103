<template>
	<div>
		<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
			<a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading">
				<template slot="leader" slot-scope="leader">
					<div class="table-avatar-info">
						<div class="avatar-info">
							<h6 v-if="leader && leader.nickname">{{ leader ? leader.name : '' }}</h6>
						</div>
					</div>
				</template>
				<template slot="editBtn" slot-scope="row">
					<div style="display: flex;">
						<a-button type="link" :data-id="row.group_name" class="edit-btn" @click="showSet(row)">
							设置群负责人
						</a-button>
						<a-button type="link" :data-id="row.group_name" class="edit-btn" @click="toDetail(row)">
							查看详情
						</a-button>
					</div>
				</template>
			</a-table>
		</a-card>
		<a-modal v-model:visible="visible" title="设置群负责人" okText="确认" cancelText="取消" :maskClosable="false"
			:afterClose="afterClose" @ok="handleOk" :confirm-loading="confirmLoading">
			<a-row :gutter="24" type="flex">
				<a-col :span="24" :lg="6" class="mb-12">
					<div style="line-height: 40px;font-weight: 500;">选择负责人:</div>
				</a-col>
				<a-col :span="24" :lg="18" class="mb-12">
					<a-select :value="name" @change="handleChange" show-search style="width: 100%">
						<a-select-option v-for="item in managers" :key="item.mobile" :value="item.name">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
		</a-modal>
	</div>
</template>

<script>
import { setWxWorkGroupLeader, getManagers } from '../../http/api';
export default ({
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: () => true,
		},
	},
	data() {
		return {
			nickname: '',
			name: '',
			phone: '',
			groupName: '',
			visible: false,
			authorsHeaderBtns: 'all',
			confirmLoading: false,
			managers: []
		}
	},
	mounted() {
		this.getManagers()
	},
	methods: {
		handleChange(e) {
			let manager = this.managers.find(item => item.name == e)
			this.nickname = manager.name
			this.name = manager.name
			this.phone = manager.mobile
		},
		getManagers() {
			getManagers().then(res => {
				console.log(res)
				if (res.errors) {
					this.$message.error(res.errors[0].message)
					return
				}
				this.managers = res.data.wxworkChat.managers
			})
		},
		afterClose() {
			this.visible = false
			this.nickname = ''
			this.name = ''
			this.phone = ''
			this.groupName = ''
		},
		handleOk() {
			console.log('ok')
			if (this.nickname && this.name && this.phone && this.groupName) {
				this.confirmLoading = true
				setWxWorkGroupLeader({
					variables: {
						groupName: this.groupName,
						leader: {
							nickname: this.nickname,
							name: this.name,
							phone: this.phone,
						}
					}
				}).then(res => {
					console.log(res)
					if (res.errors) {
						this.$message.error(res.errors[0].message)
						return
					}
					this.confirmLoading = false
					this.$message.success('设置成功')
					this.$emit('refresh')
					this.visible = false
				}).catch(() => {
					this.$message.error('设置失败')
					this.confirmLoading = false
				})
			} else {
				this.$message.error('请填写完整信息')
			}
		},
		showSet(item) {
			console.log(item)
			this.groupName = item.group_name
			this.nickname = item.leader?.nickname || ''
			this.name = item.leader?.name || ''
			this.phone = item.leader?.phone || ''
			this.visible = true
		},
		toDetail(item) {
			console.log(item)
			this.$router.push({
				path: '/detail',
				query: {
					groupName: item.group_name,
					nickname: item.leader?.nickname || '',
				}
			})
		}
	},
})

</script>

<style lang="scss" scoped>
.edit-btn {
	border: 1px solid #E5E7EB;

	&~.edit-btn {
		margin-left: 12px;
	}
}

.mb-12 {
	margin-bottom: 12px;
}</style>